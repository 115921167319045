import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inicio', url: '/inicio', icon: 'home' },
    { title: 'Agenda', url: '/agenda', icon: 'reader' },
    { title: 'Farmacia de Turno', url: '/farmacias', icon: 'medkit' },
    { title: 'Turismo', url: '/turismo', icon: 'map' },
    { title: 'Alojamientos temporarios', url: '/alojamientos', icon: 'home' },
    { title: 'Faq', url: '/faq', icon: 'flag' },
    { title: 'Contacto', url: '/contacto', icon: 'heart' },
  ];
  public labels = ['Version: 3.2.6'];

  constructor(private platform: Platform, private router: Router) {
    this.platform.backButton.subscribeWithPriority(10, () => {
      const url = this.router.url;

      if (url !== '/inicio') {
        this.router.navigate(['/inicio']);
      } else if (url === '/inicio') {
        App.exitApp();
      }
    });
  }

}


